.grid-header {
    position: sticky;
    top: 0;
    left: 0;
    background-color: transparent !important;
    z-index: 1;
    /* margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px; */
}
.grid-stripped ion-row.grid-header > ion-col:first-child {
    font-weight: normal;
}
.grid-stripped ion-row.grid-header > ion-col {
    color: inherit !important;
}
.grid-stripped ion-row:nth-child(even) {
    background: #e6e6e6
}
.grid-stripped ion-row:nth-child(odd) {
    background: #f5f5f5
}

.grid-stripped ion-row > ion-col:first-child {
    font-weight: bold;
}
.grid-stripped ion-col {
    color: var(--ion-color-dark)
}

