@media (prefers-color-scheme: light) {
    ion-content {
        --background: linear-gradient(#ffffff, #e1e8ff);
    }
}
.control-wrapper {
    display: flex;
    justify-content: center;
    margin: 1em 0.5em;
    gap: 3em;
}

.ori-image-wrapper {
    display: flex;
    justify-content: center;
    margin: 40px 0 20px 0;
}

.ori-image {    
    width: 33%;
    max-width: 200px;
    margin: 0 auto;
}
.ori-image-spinning {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(-360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(-360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(-360deg); 
        transform:rotate(-360deg); 
    } 
}

  
@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 0px
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px
    
    }

    100% {
        stroke-dashoffset: 960px;
    
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px 
    }

    100% {
        stroke-dashoffset: 960px
    }
}

@keyframes colored-circle { 
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

.blink-me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
  
.inlinesvg .svg svg {
    display: inline
}

.icon-success svg polyline {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon-success svg circle#colored {
    -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
} 