.card-about {
    margin-top: 40px;
}

.logo-img {
    max-width: 250px !important;
    /* transform: scale(0.5); */
    margin: 20px auto;
}

.logo-img img {
    margin: 0 50%;
}

ion-card-header,
ion-card-content {
    text-align: center;
}